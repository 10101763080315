import { useState, useEffect } from 'react';
import { Switch, FormControlLabel, IconButton } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { CANCEL_ORDER, CREDIT_ORDER, GET_ORDERS } from './Orders.graphql';
import { Order } from '../../interfaces';
import { GridValidRowModel, nbNO, DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import {
  Cancel,
  CancelRounded,
  ErrorRounded,
  CheckCircleRounded,
  CreditCard,
  AttachMoney,
  PictureAsPdf,
  Receipt,
  Info,
  Lens,
  LensOutlined,
  NotInterestedOutlined,
  GetApp,
  Undo,
} from '@mui/icons-material';
import { formatDate, numberAsKr } from '../../Formatters';
import { OrderStatus, OrderType, PaymentMode } from '../../interfaces.enums';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConfirmWithInvoiceDialog from './ConfirmWithInvoiceDialog';
import { useConfig } from '../../components/Configuration';
import Fuse from 'fuse.js';
import { SearchField } from '../../components/SearchField';

interface Props {
  showIncomplete: boolean;
  setShowIncomplete(val: boolean): void;
  licenseId?: number;
}

export function OrderSearchableTable({ showIncomplete, setShowIncomplete, licenseId }: Props) {
  const [showCard, setShowCard] = useState(true);
  const [showInvoice, setShowInvoice] = useState(true);
  const [showNonInvoiceable, setShowNonInvoiceable] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { pageSize, page } = paginationModel;
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const { loading, data } = useQuery(GET_ORDERS, {
    variables: licenseId ? { showIncomplete, page, pageSize, licenseId } : { showIncomplete, page, pageSize },
    fetchPolicy: 'no-cache',
    pollInterval: pollIntervall,
  });
  const isLoading = loading || !data;

  const orders: Order[] = [];

  useEffect(() => {
    if (!loading && data) {
      setIsDataLoading(true);
      const renderTimeout = setTimeout(() => setIsDataLoading(false), 200); // Adjust time as needed
      return () => clearTimeout(renderTimeout); // cleanup on component unmount or data change
    }
  }, [loading, data]);

  if (data && data.orders) {
    orders.push(
      ...data.orders.orders.map((order) => ({
        id: order.id,
        orderNumber: order.orderNumber,
        invoiceNumber: order.invoiceNumber,
        creditInvoiceNumber: order.creditInvoiceNumber,
        registrationEmail: order.registrationEmail,
        user: order.user,
        license: order.license,
        amount: order.amount,
        description: order.description,
        type: order.type,
        paymentMode: order.paymentMode,
        status: order.status,
        startedAt: order.startedAt,
        invoiceContentId: order.invoiceContentId,
        creditInvoiceContentId: order.creditInvoiceContentId,
        errorMessage: order.errorMessage,
        isInvoiceable: order.isInvoiceable,
      })),
    );
  }

  const options = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0.0, // Adjust this value for more or less strict matching
    keys: ['orderNumber', 'user.displayName', 'license.companyName', 'description'],
  };
  const isAuthorized = (order: Order): boolean => {
    return (
      order.status === OrderStatus.Completed ||
      order.status === OrderStatus.Fullfilled ||
      order.status === OrderStatus.Credited ||
      (order.type === OrderType.Invoice && order.status === OrderStatus.Open)
    );
  };

  // Filter orders based on the search term
  const filterOrders = (): Order[] => {
    const filteredOrders = orders.filter(
      (x) =>
        (showCard || x.type !== OrderType.Card) &&
        (showInvoice || x.type !== OrderType.Invoice || (showNonInvoiceable && !x.isInvoiceable)) &&
        (showNonInvoiceable || x.isInvoiceable) &&
        (showIncomplete || isAuthorized(x)),
    );

    if (searchTerm) sessionStorage.setItem('searchTerm', searchTerm);

    if (!searchTerm) {
      const cachedSearchTerm = sessionStorage.getItem('searchTerm');
      if (cachedSearchTerm) {
        setSearchTerm(cachedSearchTerm);
      }
      return filteredOrders;
    } else {
      const fuse = new Fuse(filteredOrders, options);
      const filtered = fuse.search(searchTerm.trim()); // Specify the return type

      return filtered;
    }
  };

  const filteredOrders = filterOrders();

  useEffect(() => {
    if (data) {
      setRowCountState(data.orders.count);
    }
  }, [data]);

  const rowCount = data?.orders.count ?? 0;
  const [rowCountState, setRowCountState] = useState(rowCount);
  const apiRef = useGridApiRef();
  const rows: GridValidRowModel[] = filteredOrders.map((x) => {
    return {
      id: x.id,
      userId: x.user?.id,
      licenseId: x.license?.id,
      orderNumber: x.orderNumber,
      user: x.user?.displayName,
      customer: x.license?.companyName,
      description: x.description,
      amount: x.amount,
      type: x.type,
      status: x.status,
      isInvoiceable: x.isInvoiceable,
      paymentMode: x.paymentMode,
      date: x.startedAt,
      pdf: x.invoiceNumber,
      action: '...',
      errorMessage: x.errorMessage,
      invoiceContentId: x.invoiceContentId,
      creditInvoiceContentId: x.creditInvoiceContentId,
      creditInvoiceNumber: x.creditInvoiceNumber,
      invoiceNumber: x.invoiceNumber,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: 'Nummer',
      width: 90,
      renderCell: (params) => <div style={{ textAlign: 'right' }}>{params.value}</div>,
    },
    {
      field: 'user',
      headerName: 'Bruker',
      width: 150,
      renderCell: (params) => {
        var test = params.row;
        console.log({ test });
        const { userId, user } = params.row || {};
        return userId ? <Link to={`/user/${userId}`}>{user}</Link> : <span>&lt;Slettet&gt;</span>;
      },
    },
    {
      field: 'customer',
      headerName: 'Kunde',
      width: 150,
      renderCell: (params) => {
        const { licenseId, customer } = params.row || {};
        return licenseId ? <Link to={`/license/${licenseId}`}>{customer || '<Mangler navn>'}</Link> : null;
      },
    },
    {
      field: 'description',
      headerName: 'Beskrivelse',
      width: 200,
      renderCell: (params) => (
        <span title={params.value} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.value}
        </span>
      ),
    },
    {
      field: 'amount',
      headerName: 'Beløp',
      width: 130,
      renderCell: (params) => {
        const { isInvoiceable, status } = params.row;
        const amount = numberAsKr(params.value);
        if (!isInvoiceable) return `Ikke-fakturert ${amount}`;
        if (status === OrderStatus.Credited) return `Kreditert ${amount}`;
        return amount;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 90,
      renderCell: (params) => {
        const { isInvoiceable, type } = params.row;
        if (!isInvoiceable) return <NotInterestedOutlined titleAccess="Ikke-fakturerbar" />;
        if (type === OrderType.Card) return <CreditCard titleAccess="Kort" />;
        if (type === OrderType.Invoice) return <AttachMoney titleAccess="Faktura" />;
        return type;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const { status, errorMessage, paymentMode, type, isInvoiceable } = params.row;
        switch (status) {
          case OrderStatus.Open:
            return <LensOutlined titleAccess="Åpen" />;
          case OrderStatus.Cancelled:
            return <CancelRounded color="error" titleAccess="Avbrutt" />;
          case OrderStatus.Failed:
            return <ErrorRounded color="error" titleAccess={`Feilet: ${errorMessage}`} />;
          case OrderStatus.Reserved:
            return <Lens color="success" titleAccess="Beløp reservert" />;
          case OrderStatus.Completed:
            if (type === OrderType.Card) return <CheckCircleRounded color="success" titleAccess="Betalt" />;
            if (!isInvoiceable) return <CheckCircleRounded color="success" titleAccess="Markert som ikke-fakturerbar" />;
            if (paymentMode === PaymentMode.Manual) return <Info titleAccess="Håndteres manuelt i Maconomy" />;
            return <CheckCircleRounded color="success" titleAccess="Faktura sendt" />;
          case OrderStatus.Fullfilled:
            return <CheckCircleRounded color="success" titleAccess="Faktura sendt manuelt" />;
          case OrderStatus.Credited:
            return <CheckCircleRounded titleAccess="Kreditert" />;
          default:
            return status;
        }
      },
    },
    {
      field: 'date',
      headerName: 'Dato',
      width: 130,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'pdf',
      headerName: 'Faktura',
      width: 90,
      renderCell: (params) => {
        const { invoiceContentId, invoiceNumber, creditInvoiceContentId, creditInvoiceNumber } = params.row;

        const rootUrl = config ? config.apiUrl : '/';

        return (
          <>
            {invoiceContentId ? (
              <a href={rootUrl + '/content/' + invoiceContentId} target="_blank" rel="noreferrer">
                <PictureAsPdf titleAccess="Åpne faktura i ny fane" />
              </a>
            ) : invoiceNumber ? (
              <Receipt titleAccess={`Faktura opprettet: ${invoiceNumber}`} />
            ) : null}
            {creditInvoiceContentId ? (
              <a href={rootUrl + '/content/' + creditInvoiceContentId} target="_blank" rel="noreferrer">
                <PictureAsPdf titleAccess="Åpne kreditnota i ny fane" />
              </a>
            ) : creditInvoiceNumber ? (
              <Receipt titleAccess={`Kreditnota opprettet: ${creditInvoiceNumber}`} />
            ) : null}
          </>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      renderCell: (params) => {
        return <Action order={params.row} />;
      },
    },
  ];

  const Action = ({ order }: { order: Order }) => {
    if (order.type === OrderType.Card && (order.status === OrderStatus.Open || order.status === OrderStatus.Reserved)) {
      return <AbortAction order={order} />;
    }

    if (order.status === OrderStatus.Failed) {
      return <UpdateStatusAction order={order} />;
    }

    if (order.invoiceNumber && !order.creditInvoiceNumber) {
      return <CreditAction order={order} />;
    } else return null;
  };

  function AbortAction({ order }: { order: Order }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [cancel, { called, loading }] = useMutation(CANCEL_ORDER, { variables: { input: { id: order.id } } });
    const doCancel = () => {
      setShowConfirm(false);
      cancel();
    };

    return (
      <>
        <IconButton disabled={called || loading} onClick={() => setShowConfirm(true)} size="small" title="Avbryt ordre">
          <Cancel />
        </IconButton>
        <ConfirmDialog
          open={showConfirm}
          title="Avbryt ordre"
          message={`Vil du avbryte ordre ${order.orderNumber}?`}
          confirmText="Avbryt ordre"
          denyText="Lukk"
          deny={() => setShowConfirm(false)}
          confirm={doCancel}
        />
      </>
    );
  }

  function UpdateStatusAction({ order }: { order: Order }) {
    const [showConfirm, setShowConfirm] = useState(false);

    return (
      <>
        <IconButton onClick={() => setShowConfirm(true)} size="small" title="Oppdater status">
          <GetApp />
        </IconButton>
        <ConfirmWithInvoiceDialog
          order={order}
          open={showConfirm}
          title="Oppdater status"
          orderNumber={order.orderNumber}
          deny={() => setShowConfirm(false)}
        />
      </>
    );
  }

  function CreditAction({ order }: { order: Order }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [credit, { called, loading }] = useMutation(CREDIT_ORDER, { variables: { input: { id: order.id } } });
    const doCredit = () => {
      setShowConfirm(false);
      credit();
    };

    return (
      <>
        <IconButton disabled={called || loading} onClick={() => setShowConfirm(true)} size="small" title="Kreditér ordre">
          <Undo />
        </IconButton>
        <ConfirmDialog
          open={showConfirm}
          title="Kreditér ordre"
          message={`Vil du kreditére ordre ${order.orderNumber}?`}
          confirmText="Kreditér"
          deny={() => setShowConfirm(false)}
          confirm={doCredit}
        />
      </>
    );
  }

  return (
    <div>
      <FormControlLabel control={<Switch onChange={() => setShowCard(!showCard)} checked={showCard} />} label="Kort" />
      <FormControlLabel control={<Switch onChange={() => setShowInvoice(!showInvoice)} checked={showInvoice} />} label="Faktura" />
      <FormControlLabel
        control={<Switch onChange={() => setShowNonInvoiceable(!showNonInvoiceable)} checked={showNonInvoiceable} />}
        label="Ikke-fakturerbar"
      />
      <FormControlLabel control={<Switch onChange={() => setShowIncomplete(!showIncomplete)} checked={showIncomplete} />} label="Ufullstendige" />
      <SearchField filter={setSearchTerm} parentSearchTerm={searchTerm} />
      {isLoading && <div style={{ marginBottom: '10px' }}>Loading...</div>}
      <DataGridPro
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={rowCountState}
        pageSizeOptions={[100, 300, 500]}
        onPaginationModelChange={setPaginationModel}
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: paginationModel.pageSize,
              page: paginationModel.page,
            },
          },
        }}
        loading={isDataLoading}
      />
    </div>
  );
}
